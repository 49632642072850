import Vapi from '@vapi-ai/web';
import { v4 as uuidv4 } from 'uuid';
import { VAPI_CONFIG, CALL_STATUS, VAPI_API_KEY } from '../components/VapiConstants';
import { useCreditsStore } from '../store/useCreditsStore';
import supabase from '../config/supabaseClient';
import { CallHistoryService } from './callHistory';
import { useAuthStore } from '../store/useAuthStore';

export const vapi = new Vapi(VAPI_API_KEY.PUBLIC_API_KEY);

export class VapiService {
  private static instance: VapiService | null = null;
  private activeCallId: string | null = null;
  
  private isCallActive: boolean = false;
  private isMuted: boolean = false;
  private recordingUrl: string | undefined = undefined;
  private transcriptCallback: ((text: string, speaker: string) => void) | null = null;
  private currentTranscript: string = '';  
  private currentSpeaker: string = 'Unknown';  
  private latestTranscript: string = '';
  private callStartTime: number | null = null;
  private callEndCallback: (() => void) | null = null;
  private isCallEndHandled: boolean = false; // Flag to track if call-end has been handled
  private eventListeners: { [event: string]: () => void } = {};


  constructor(onTranscript: (text: string, speaker: string) => void) {
    this.transcriptCallback = onTranscript;

    // Store event listeners for cleanup
    this.eventListeners['call-start'] = () => vapi.on('call-start', () => {
      console.log('Call started');
      this.isCallEndHandled = false;
      this.callStartTime = Date.now();
      this.currentTranscript = '';
      this.latestTranscript = '';
      this.currentSpeaker = 'Unknown';

      if (this.transcriptCallback) {
        this.transcriptCallback('', 'system-reset');
      }
    });

    this.eventListeners['call-end'] = () => vapi.on('call-end', async () => {
      if (this.isCallEndHandled) {
        console.log('Call-end already handled, skipping.');
        return;
      }
      this.isCallEndHandled = true;

      console.log('Call ended');
      this.activeCallId = null;
      this.isCallActive = false;
      const callDuration = this.getCallDuration();
      console.log('Call duration:', callDuration);

      if (callDuration > 0) {
        console.log('Deducting credits for call duration:', callDuration);
        await useCreditsStore.getState().deductCredits(callDuration);
      } else {
        console.warn('Call duration was zero, no credits deducted.');
      }

      if (this.callEndCallback) {
        this.callEndCallback();
      }
    });

    vapi.on('call-end', async () => {
      if (this.isCallEndHandled) {
        console.log('Call-end already handled, skipping.');
        return;
      }
      this.isCallEndHandled = true; // Mark as handled

      console.log('Call ended');
      this.activeCallId = null;
      this.isCallActive = false;
      const callDuration = this.getCallDuration();
      console.log('Call duration:', callDuration);

      if (callDuration > 0) {
        console.log('Deducting credits for call duration:', callDuration);
        await useCreditsStore.getState().deductCredits(callDuration);
      } else {
        console.warn('Call duration was zero, no credits deducted.');
      }

      if (this.callEndCallback) {
        this.callEndCallback();
      }
    });
    
    vapi.on('speech-start', () => {
      console.log('Assistant started speaking');
    });

    vapi.on('speech-end', () => {
      console.log('Assistant stopped speaking');
    });

    vapi.on('error', (error) => {
      console.error('Vapi error:', error);
    });

    vapi.on('message', (msg) => {
      if (msg.type !== 'transcript') return;

      this.currentTranscript = '';
      this.latestTranscript = '';
      this.currentSpeaker = 'Unknown';

      if (msg.transcriptType === 'partial' || msg.transcriptType === 'final') {
        this.latestTranscript = msg.transcript;
        this.currentSpeaker = msg.speaker || 'Unknown';

        if (this.transcriptCallback) {
          this.transcriptCallback(this.latestTranscript, this.currentSpeaker);
        }
      }
    });
  }

  public static getInstance(onTranscript: (text: string, speaker: string) => void): VapiService {
    // Always create a new instance to ensure fresh state
    return new VapiService(onTranscript);
  }

  public getCurrentTranscript(): string {
    console.log("Returning latest transcript:", this.latestTranscript);
    return this.latestTranscript;
  }

  public getCurrentSpeaker(): string {
    return this.currentSpeaker;
  }

  public getCallDuration(): number {
    if (!this.callStartTime) {
      return 0;
    }
    return Math.round((Date.now() - this.callStartTime) / 1000);
  }

  public async startCall(assistantId: string, userId: string): Promise<string> {
    try {
      console.log('Starting Vapi call with assistant:', assistantId);
      if (!vapi) throw new Error('Vapi instance is not initialized');
      
      // Reset transcript and speaker state
      this.currentTranscript = ''; 
      this.latestTranscript = ''; 
      this.currentSpeaker = 'Unknown';
      
      // Signal the UI to clear previous transcripts
      if (this.transcriptCallback) {
        this.transcriptCallback('', 'system-reset');
      }
  
      // Check if user is admin
      const isAdmin = await this.isAdminUser(userId);
      
      // Start the call with VAPI
      const callResponse = await vapi.start(assistantId);
      
      // Store the actual call ID from VAPI's response
      if (callResponse && callResponse.id) {
        this.activeCallId = callResponse.id; 
        this.isCallActive = true;
        this.callStartTime = Date.now();
        console.log("Call id from VAPI = ", this.activeCallId);

        // For admin users, we need to ensure we're still saving the call info
        // but we need to handle the different user ID format
        if (isAdmin) {
          console.log("Admin user detected, handling call record differently");
          const { data, error } = await supabase
            .from('user_calls')
            .insert([{ 
              user_id: userId, // This might be a string ID for admin
              call_id: this.activeCallId,
              is_admin_call: true // Add a flag to identify admin calls
            }]);

          if (error) {
            console.error('Error saving admin call ID to Supabase:', error);
          } else {
            console.log('Admin VAPI Call ID saved to Supabase successfully:', data);
          }
        } else {
          // Regular user flow remains unchanged
          const { data, error } = await supabase
            .from('user_calls')
            .insert([{ user_id: userId, call_id: this.activeCallId }]);

          if (error) {
            console.error('Error saving call ID to Supabase:', error);
          } else {
            console.log('VAPI Call ID saved to Supabase successfully:', data);
          }
        }
      } else {
        throw new Error('Failed to start call: callResponse is null or missing id');
      }
      
      return this.activeCallId; // Return the real call ID
    } catch (error) {
      console.error('Failed to start call:', error);
      throw error;
    }
  }
  
  // Helper method to check if a user is the admin
  private async isAdminUser(userId: string): Promise<boolean> {
    // First check if the current user in store is the admin
    const authStore = useAuthStore.getState();
    if (authStore.user && authStore.user.email === 'rraj@growthpods.io') {
      return true;
    }
    
    // If not found in store, try to fetch from database
    try {
      const { data, error } = await supabase
        .from('users')
        .select('email')
        .eq('id', userId)
        .single();
        
      if (error) {
        console.error('Error checking admin status:', error);
        return false;
      }
      
      return data?.email === 'rraj@growthpods.io';
    } catch (error) {
      console.error('Error in isAdminUser check:', error);
      return false;
    }
  }
  
  public async stopCall(): Promise<void> {
    try {
      console.log('Stopping Vapi call');
      if (!vapi) throw new Error('Vapi instance is not initialized');
      
      // Calculate total call duration before stopping
      const totalDuration = this.getCallDuration();
      console.log(`Call duration before stopping: ${totalDuration} seconds`);
      
      await vapi.stop();
      this.isCallActive = false;
    } catch (error) {
      console.error('Failed to stop call:', error);
      throw error;
    }
  }

  public toggleMute(): void {
    console.log("Muting User...")
    if (!vapi) {
      console.error('Vapi instance is not initialized');
      return;
    }
    this.isMuted = !this.isMuted;
    vapi.setMuted(this.isMuted);
    console.log(`Mute state changed to: ${this.isMuted}`);
  }

  public async cleanup(): Promise<void> {
    console.log('Cleaning up VapiService instance');
    if (this.isCallActive) {
      await this.stopCall();
    }

    // Remove all event listeners
    Object.values(this.eventListeners).forEach((unsubscribe) => unsubscribe());
    this.eventListeners = {};
  }

  public getRecordingUrl(): string | undefined {
    return this.recordingUrl;
  }

  public setCallEndCallback(callback: () => void): void {
    this.callEndCallback = callback;
  }

  public getActiveCallId(): string | null {
    return this.activeCallId ?? null;
  }
}