import { create } from 'zustand';
import type { Buyer, CallSession } from '../types';

interface Store {
  buyers: Buyer[];
  sessions: CallSession[];
  currentBuyer: Buyer | null;
  setCurrentBuyer: (buyer: Buyer | null) => void;
  addSession: (session: CallSession) => void;
}

export const useStore = create<Store>((set) => ({
  buyers: [
    // Sales Personas
    {
      id: '1',
      name: 'Sarah Rodriguez',
      title: 'CIO',
      company: 'BioLife Sciences',
      avatar: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?w=400',
      callType: 'Warm Call',
      personality: ['Strategic', 'Innovation Focused', 'Data-Driven'],
      metrics: {
        decisionTime: '2-3 weeks',
        budgetRange: '$100k-$500k',
        stakeholders: 4,
        successRate: 68
      },
      isOnline: true,
      country: 'USA'
    },
    {
      id: '2',
      name: 'Michael Chen',
      title: 'VP of IT',
      company: 'RetailGiant Corp',
      avatar: 'https://images.unsplash.com/photo-1560250097-0b93528c311a?w=400&h=400&fit=crop',
      callType: 'Cold Call',
      personality: ['Analytical', 'ROI Focused', 'Process-Oriented'],
      metrics: {
        decisionTime: '4-6 weeks',
        budgetRange: '$50k-$200k',
        stakeholders: 3,
        successRate: 45
      },
      isOnline: true,
      country: 'USA'
    },
    // HR Personas
    {
      id: '3',
      name: 'Emma Thompson',
      title: 'Head of Digital',
      company: 'TechForward Solutions',
      avatar: 'https://images.unsplash.com/photo-1438761681033-6461ffad8d80?w=400',
      callType: 'Performance Review',
      personality: ['Empathetic', 'Development-Focused', 'Policy-Driven'],
      metrics: {
        timeToHire: '45 days',
        retentionRate: '92%',
        employeeNPS: 8.5,
        successRate: 75
      },
      isOnline: false,
      country: 'USA'
    },
    // Customer Service Personas
    /* {
      id: '4',
      name: 'David Cooper',
      title: 'Support Team Lead',
      company: 'ServiceFirst Inc',
      avatar: 'https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?w=400',
      callType: 'Customer Escalation',
      personality: ['Patient', 'Solution-Oriented', 'Customer-Centric'],
      metrics: {
        responseTime: '< 2 hours',
        satisfactionScore: 4.8,
        resolutionRate: '94%',
        successRate: 88
      },
      isOnline: true,
      country: 'USA'
    }, */
    // Education Personas
    /* {
      id: '5',
      name: 'Sophie Mitchell',
      title: 'Education Coordinator',
      company: 'EduTech Learning',
      avatar: 'https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?w=400',
      callType: 'Student Guidance',
      personality: ['Supportive', 'Goal-Oriented', 'Detail-Focused'],
      metrics: {
        studentSuccess: '89%',
        programCompletion: '92%',
        satisfactionRate: 4.7,
        successRate: 82
      },
      isOnline: true,
      country: 'USA'
    }, */
    // Recruitment Personas
    /* {
      id: '6',
      name: 'James Wilson',
      title: 'Technical Recruiter',
      company: 'TalentHub Solutions',
      avatar: 'https://images.unsplash.com/photo-1519085360753-af0119f7cbe7?w=400',
      callType: 'Technical Interview',
      personality: ['Analytical', 'Assessment-Focused', 'Communication-Driven'],
      metrics: {
        offerAcceptRate: '78%',
        timeToOffer: '21 days',
        candidateNPS: 8.8,
        successRate: 71
      },
      isOnline: false,
      country: 'USA'
    }, */
    {
      id: '7',
      name: 'John',
      title: 'Founder & CEO',
      company: 'Early-stage startup',
      avatar: 'https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?w=400&h=400&fit=crop',
      callType: 'Performance Review',
      personality: ['Straight to the point', 'Skeptical but open-minded', 'Pressed for time'],
      metrics: {
        offerAcceptRate: '78%',
        timeToOffer: '21 days',
        candidateNPS: 8.8,
        successRate: 71
      },
      isOnline: true,
      country: 'USA'
    }, 
    {
      id: '8',
      name: 'Shimmer',
      title: 'CTO',
      company: 'U.S.-based tech company',
      avatar: 'https://images.unsplash.com/photo-1598550874175-4d0ef436c909?w=400&h=400&fit=crop',
      callType: 'Cold Call',
      personality: ['Strategic but skeptical', 'Not impulsive', 'Data-driven','Open-minded but busy'],
      metrics: {
        decisionTime: '4-6 weeks',
        budgetRange: '$50k-$200k',
        stakeholders: 3,
        successRate: 45
      },
      isOnline: true,
      country: 'USA'
    },
    {
      id: '9',
      name: 'Eva',
      title: 'Head of HR',
      company: 'Mid-sized company',
      avatar: 'https://images.unsplash.com/photo-1580489944761-15a19d654956?w=400&h=400&fit=crop',
      callType: 'Performance Review',
      personality: ['Experienced & Direct', 'Skeptical & Busy', 'Comfortable with Deel'],
      metrics: {
        offerAcceptRate: '78%',
        timeToOffer: '21 days',
        candidateNPS: 8.8,
        successRate: 71
      },
      isOnline: true,
      country: 'USA'
    },
    {
      id: '10',
      name: 'James Mitchell',
      title: 'Chief Technology Officer',
      company: 'TechVision Australia',
      avatar: 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?w=400&h=400&fit=crop',
      callType: 'Technical Interview',
      personality: ['Busy Executive', 'Professional and Approachable Tone', 'Skeptical but Open'],
      metrics: {
        offerAcceptRate: '78%',
        timeToOffer: '21 days',
        candidateNPS: 8.8,
        successRate: 71
      },
      isOnline: true,
      country: 'Australia'
    }
    ,
    {
        id: '11',
        name: "Olivia Anderson",
        title: "Head of Digital Innovation",
        company: "Sydney Financial Group",
        avatar: "https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?w=400&h=400&fit=crop",
        callType: 'Webinar Invitation',
        personality: ["Innovative Thinker", "Strategic", "Results-Oriented"],
        metrics: {
            offerAcceptRate: "82%",
            timeToOffer: "19 days",
            candidateNPS: 9.0,
            successRate: 74
        },
        isOnline: true,
        country: "Australia"
    },
    {
        id: '12',
        name: "William Cooper",
        title: "IT Director",
        company: "Melbourne Health Tech",
        avatar: "https://images.unsplash.com/photo-1519085360753-af0119f7cbe7?w=400&h=400&fit=crop",
        callType: "Performance Review",
        personality: ["Analytical", "Focused", "Tech-Driven"],
        metrics: {
            offerAcceptRate: "79%",
            timeToOffer: "22 days",
            candidateNPS: 8.7,
            successRate: 72
        },
        isOnline: false,
        country: "Australia"
    },
    {
        id: '13',
        name: "Sophie Laurent",
        title: "Chief Digital Officer",
        company: "Paris Innovation Hub",
        avatar: "https://images.unsplash.com/photo-1534528741775-53994a69daeb?w=400&h=400&fit=crop",
        callType: "Technical Interview",
        personality: ["Visionary", "Forward-Thinking", "Tech Enthusiast"],
        metrics: {
            offerAcceptRate: "85%",
            timeToOffer: "18 days",
            candidateNPS: 9.2,
            successRate: 76
        },
        isOnline: true,
        country: "European Union"
    },
    {
        id: '14',
        name: "Klaus Weber",
        title: "Head of Enterprise IT",
        company: "Berlin Tech Solutions",
        avatar: "https://images.unsplash.com/photo-1562788869-4ed32648eb72?q=80&w=2072&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        callType: "Technical Interview",
        personality: ["Data-Driven", "Precise", "Strategic"],
        metrics: {
            offerAcceptRate: "80%",
            timeToOffer: "20 days",
            candidateNPS: 8.5,
            successRate: 73
        },
        isOnline: false,
        country: "European Union"
    },
    {
        id: '15',
        name: "Elena Rossi",
        title: "Innovation Director",
        company: "Milano Digital",
        avatar: "https://plus.unsplash.com/premium_photo-1661576481589-6278a8595fd4?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        callType: "Event Invitation",
        personality: ["Creative", "Problem-Solver", "Innovative"],
        metrics: {
            offerAcceptRate: "83%",
            timeToOffer: "17 days",
            candidateNPS: 9.1,
            successRate: 75
        },
        isOnline: true,
        country: "European Union"
    },
    {
        id: '16',
        name: "Arjun Patel",
        title: "Chief Information Officer",
        company: "Bangalore Tech Solutions",
        avatar: "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?w=400&h=400&fit=crop",
        callType: "Event Invitation",
        personality: ["Strategic", "Solution-Oriented", "Tech Visionary"],
        metrics: {
            offerAcceptRate: "81%",
            timeToOffer: "21 days",
            candidateNPS: 8.9,
            successRate: 74
        },
        isOnline: false,
        country: "India"
    },
    {
        id: '17',
        name: "Priya Sharma",
        title: "Head of Digital Strategy",
        company: "Mumbai Innovation Labs",
        avatar: "https://images.unsplash.com/photo-1573497019940-1c28c88b4f3e?w=400&h=400&fit=crop",
        callType: "Event Invitation",
        personality: ["Data-Driven", "Analytical", "Goal-Oriented"],
        metrics: {
            offerAcceptRate: "84%",
            timeToOffer: "19 days",
            candidateNPS: 9.3,
            successRate: 77
        },
        isOnline: true,
        country: "India"
    },
    {
        id: '18',
        name: "Rajesh Kumar",
        title: "Technology Director",
        company: "Delhi Enterprise Systems",
        avatar: "https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?w=400&h=400&fit=crop",
        callType: "Event Invitation",
        personality: ["Experienced", "Detail-Oriented", "Pragmatic"],
        metrics: {
            offerAcceptRate: "78%",
            timeToOffer: "22 days",
            candidateNPS: 8.6,
            successRate: 72
        },
        isOnline: false,
        country: "India"
    }
  
  
  ],
  sessions: [
    {
      id: '1',
      buyerId: '1',
      startTime: new Date('2024-04-22T17:41:00'),
      endTime: new Date('2024-04-22T17:41:42'),
      analysis: {
        sentiment: 85,
        keywords: ['innovation', 'efficiency', 'cost-saving'],
        suggestions: ['Improve value proposition', 'Follow up on ROI discussion']
      }
    },
    {
      id: '2',
      buyerId: '2',
      startTime: new Date('2024-04-21T13:54:00'),
      endTime: new Date('2024-04-21T13:54:31'),
      analysis: {
        sentiment: 72,
        keywords: ['budget', 'implementation', 'timeline'],
        suggestions: ['Address budget concerns', 'Provide implementation roadmap']
      }
    },
    {
      id: '3',
      buyerId: '3',
      startTime: new Date('2024-04-20T13:57:00'),
      endTime: new Date('2024-04-20T13:57:17'),
      analysis: {
        sentiment: 68,
        keywords: ['development', 'training', 'goals'],
        suggestions: ['Focus on growth opportunities', 'Discuss career path']
      }
    }
  ],
  currentBuyer: null,
  setCurrentBuyer: (buyer) => set({ currentBuyer: buyer }),
  addSession: (session) => set((state) => ({ sessions: [...state.sessions, session] })),
}));