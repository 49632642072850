import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { VAPI_CONFIG,VAPI_API_KEY } from "../components/VapiConstants"; 

interface CallAnalytics {
  recordingUrl?: string;
  transcript?: string; 
  analysis?: {
    summary?: string;
    structuredData?: object;
    successEvaluation?: string;
  };
}

function formatStructuredData(data: any) {
  const formattedData = Object.entries(data).map(([key, value]: [string, any]) => {
    const score = value.score ? `Score: ${value.score}` : "";
    const explanation = value.explanation || "";
    let title = "";
  
    if (score !== "" || explanation !== "") {
      title = key.replace(/_/g, " ").toUpperCase();
    }
    
    return (
      <div key={key} className="p-1">
        <h4 className="font-semibold text-mg text-gray-800">{title}</h4>
        <p className="text-sm text-gray-700">{score}</p>
        <p className="text-xs text-gray-600">{explanation}</p>
      </div>
    );
  });

  return formattedData;
}


function formatAnalysisSummary(summary: string) {
  const sections = summary.split('\n').filter(line => line.trim() !== "");

  const formattedSummary = sections.map((line, index) => {
   
    if (line.match(/^(\d+\.)/)) {
     
      return (
        <div key={index} className="p-3 border-b">
          <h5 className="font-semibold text-mg text-gray-800">{line}</h5>
        </div>
      );
    } else {
      
      return (
        <div key={index} className="p-3">
          <p className="text-sm text-gray-700">{line}</p>
        </div>
      );
    }
  });

  return <div>{formattedSummary}</div>;
}


function formatSuccessEvaluation(evaluation: string) {
  const sections = evaluation.split('\n').filter(line => line.trim() !== "");

  const formattedEvaluation = sections.map((line, index) => {
   
    if (line.match(/^([A-Za-z\s]+)/)) {
      return (
        <div key={index} className="p-3 border-b">
          <h5 className="font-semibold text-lg text-gray-800">{line}</h5>
        </div>
      );
    } else if (line.match(/^(\d+\.)/)) {
     
      return (
        <div key={index} className="p-1">
          <p className="text-m text-gray-700">{line}</p>
        </div>
      );
    } else {
     
      return (
        <div key={index} className="p-1">
          <p className="text-s text-gray-600">{line}</p>
        </div>
      );
    }
  });

  return <div>{formattedEvaluation}</div>;
}



function formatTranscription(transcription: string) {
  
  const messages = transcription.split(/(?=AI:|User:)/);

  const formattedTranscription = messages.map((message, index) => {
    
    const speaker = message.startsWith('AI:') ? 'AI' : 'User';

    const content = message.replace(/^AI: |^User: /, '').trim();

    return (
      <div key={index} className={`p-1 ${speaker === 'AI' ? 'bg-grey-100' : 'bg-grey-100'} my-2`}>
        <p className="font-semibold text-l">{speaker}</p>
        <p className="text-sm text-gray-700">{content}</p>
      </div>
    );
  });

  return <div>{formattedTranscription}</div>;
}



export function CallDetailsPage() {
  const { callId } = useParams<{ callId: string }>();
  const [callAnalytics, setCallAnalytics] = useState<CallAnalytics | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    const fetchCallAnalytics = async (callId: string) => {
      const token = VAPI_API_KEY.PRIVATE_API_KEY; 
      const apiUrl = `https://api.vapi.ai/call/${callId}`;

      const options = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };

      try {
        const response = await fetch(apiUrl, options);
        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }
        const callData = await response.json();
        setCallAnalytics(callData); 
      } catch (error) {
        console.error("Error fetching call analytics:", error);
      }
    };

    if (callId) {
      fetchCallAnalytics(callId);
    }
  }, [callId]);

  const handlePlayPause = () => {
    setIsPlaying(prev => !prev);
  };

  if (!callAnalytics) {
    return <div>Loading...</div>;
  }

  return ( 
    <div className="p-6">
      <div className="mb-6">
        <div className="flex items-center text-sm">
          <Link to="/app/history" className="text-gray-600 hover:text-gray-900">
            Call History
          </Link>
          <span className="text-gray-900">Call Details</span>
        </div>
      </div>

    
      <div className="bg-white rounded-t-xl shadow-sm">
        <div className="p-6">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-4">
              <div>
                <h1 className="text-xl font-semibold text-gray-900">
                  Call Details
                </h1>
              </div>
            </div>
            <div className="flex items-center space-x-3">
              <button className="px-4 py-2 text-gray-700 hover:text-gray-900">
              </button>
            </div>
          </div>
        </div>
      </div>

    
      <div className="bg-white shadow-sm rounded-b-xl p-6">
        <div className="grid grid-cols-1 gap-6">
          <div className="bg-gray-50 rounded-lg p-6">
            <div className="flex items-center justify-between mb-4">
              <h3 className="font-medium text-gray-900">Recording</h3>
              <div className="flex items-center space-x-2">
                <button className="p-2 text-gray-400 hover:text-gray-600">
                </button>
              </div>
            </div>

            
            {callAnalytics.recordingUrl && (
              <div className="bg-white rounded-lg p-4 shadow-sm">
                <audio
                  src={callAnalytics.recordingUrl}
                  controls
                  autoPlay={isPlaying}
                  className="w-full mt-4"
                />
              </div>
            )}
          </div>

         
          <div className="mt-6">
            <h3 className="font-medium text-gray-900 mb-4">Transcript</h3>
            <div className="space-y-4">
              {callAnalytics.transcript ? (
                <div className="ml-3 bg-gray-100 rounded-lg p-3">
                  <p className="text-sm text-gray-900">{formatTranscription(callAnalytics.transcript)}</p>
                </div>
              ) : (
                <p>No transcript available</p>
              )}
            </div>
          </div>

        
          <div className="mt-6">
            <h3 className="font-medium text-gray-900 mb-4">Call Analysis</h3>
            <div className="space-y-8 max-w-full">
              {callAnalytics.analysis && (
                <>
                  {callAnalytics.analysis.summary && (
                    <p className="text-sm text-gray-700">{formatAnalysisSummary(callAnalytics.analysis.summary)}</p>
                  )}
                  {callAnalytics.analysis.structuredData && (
                    <div className="bg-gray-50 p-4 rounded-lg shadow-sm">
                      {formatStructuredData(callAnalytics.analysis.structuredData)}
                    </div>
                  )}
                  {callAnalytics.analysis.successEvaluation && (
                    <p className="text-sm text-gray-700">{formatSuccessEvaluation(callAnalytics.analysis.successEvaluation)}</p>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
