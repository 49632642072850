import { createClient } from '@supabase/supabase-js';

//export const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
//export const supabaseKey = import.meta.env.VITE_SUPABASE_ANON_KEY;

export const supabaseUrl ='https://hgikgdjrynlczpknocsg.supabase.co'
export const supabaseKey='eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImhnaWtnZGpyeW5sY3pwa25vY3NnIiwicm9sZSI6ImFub24iLCJpYXQiOjE3Mzg3ODY2MzksImV4cCI6MjA1NDM2MjYzOX0.yLrPV1lkkCbli86D3fAxsYNWstnQgr0RgHjmIAeK7SI'


console.log('Supabase URL:', supabaseUrl);
console.log('Supabase Key:', supabaseKey);

const supabase = createClient(supabaseUrl, supabaseKey);

export default supabase