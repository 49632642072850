import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import {
  Phone, Users, History, BookOpen, Settings, LogOut, ChevronLeft, ChevronRight, Coins, CircuitBoard
} from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { useCreditsStore } from '../store/useCreditsStore';
import { useAuthStore } from '../store/useAuthStore';

const menuItems = [
  { icon: Users, label: 'AI Personas', path: '/app/buyers' },
  { icon: Phone, label: 'Live Call', path: '/app/calls' },
  { icon: History, label: 'Call History', path: '/app/history' },
  { icon: BookOpen, label: 'Training Material', path: '/app/training' },
];

export function Sidebar() {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const { credits, monthlyLimit, fetchCredits } = useCreditsStore();
  const { user, logout } = useAuthStore();

  useEffect(() => {
    fetchCredits(); // Fetch latest credits from Supabase
  }, [fetchCredits]);

  const toggleSidebar = () => setIsCollapsed(!isCollapsed);

  return (
    <motion.div 
      initial={{ x: -300 }}
      animate={{ x: 0, width: isCollapsed ? 80 : 280 }}
      transition={{ duration: 0.3 }}
      className="relative h-screen bg-gray-100 flex flex-col"
    >
      {/* Toggle Button */}
      <button
        onClick={toggleSidebar}
        className="absolute -right-3 top-6 bg-white border border-gray-200 rounded-full p-1 hover:bg-gray-50 transition-colors z-50"
      >
        {isCollapsed ? <ChevronRight size={16} className="text-gray-600" /> : <ChevronLeft size={16} className="text-gray-600" />}
      </button>

     {/* Logo */}
    <div className={`p-6 ${isCollapsed ? 'px-4' : ''}`}>
      <div className="flex items-center space-x-3">
        {/* Conditional rendering for the logo */}
        {isCollapsed ? (
          <div className="w-10 h-10 flex items-center justify-center bg-blue-600 text-white text-xl font-bold rounded-full">
            A
          </div>
        ) : (
          <img
            src="/alex.png" // Correct path (no `/public` prefix)
            alt="AiCoach Logo"
            className="w-27 h-14 object-cover" // Increased size and prevent distortion
          />
        )}
      </div>
    </div>

      {/* Navigation */}
      <nav className="flex-1 px-4 py-4">
        {menuItems.map((item) => (
          <NavLink key={item.path} to={item.path} className={({ isActive }) => 
            `flex items-center space-x-3 px-4 py-3 rounded-xl transition-all duration-200 mb-1 ${isActive ? 'bg-blue-50 text-blue-600' : 'text-gray-600 hover:text-blue-600 hover:bg-gray-50'}`}
          >
            <item.icon size={20} />
            {!isCollapsed && <span className="font-medium text-sm">{item.label}</span>}
          </NavLink>
        ))}
      </nav>

      {/* User Profile */}
      <div className="mt-auto px-4 py-4 space-y-4 border-t border-gray-200">
        <div className="flex items-center space-x-3">
          <img
            src={`https://ui-avatars.com/api/?name=${encodeURIComponent(user?.first_name || '')}+${encodeURIComponent(user?.last_name || '')}&background=3B82F6&color=fff`}
            alt={user?.first_name || 'User'}
            className="h-10 w-10 rounded-lg"
          />
          <AnimatePresence>
            {!isCollapsed && (
              <motion.div
                initial={{ opacity: 0, width: 0 }}
                animate={{ opacity: 1, width: 'auto' }}
                exit={{ opacity: 0, width: 0 }}
                className="overflow-hidden"
              >
                <div className="text-sm font-medium text-gray-900">
                  {user?.first_name} {user?.last_name}
                </div>
                <div className="text-xs text-gray-500">{user?.email}</div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>

        {/* Credits Section */}
        <div className={`flex items-center ${isCollapsed ? 'justify-center' : 'space-x-3'}`}>
          <Coins className="text-yellow-500" size={20} />
          {!isCollapsed && (
            <div className="text-sm font-medium text-gray-900">
              {credits} / {monthlyLimit} credits
            </div>
          )}
        </div>

        {/* Logout */}
        <button
          onClick={logout}
          className="flex items-center space-x-3 px-4 py-3 w-full rounded-xl text-gray-600 hover:text-blue-600 hover:bg-gray-50 transition-all duration-200"
        >
          <LogOut size={20} />
          {!isCollapsed && <span className="font-medium text-sm">Sign Out</span>}
        </button>
      </div>
    </motion.div>
  );
}
