import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import supabase from '../config/supabaseClient';
import { useAuthStore } from './useAuthStore';

interface CreditsStore {
  credits: number;
  monthlyLimit: number;
  isCallActive: boolean;
  renewalDate: string;
  fetchCredits: () => Promise<void>;
  deductCredits: (amount: number) => Promise<void>;
  setCallActive: (active: boolean) => void;
  getMonthlyLimit: () => number;
}

// Monthly limit for subscription plans
const PLAN_MONTHLY_LIMITS = {
  starter: 100,
  pro: 250,
  enterprise: 600,
  default: 600 // Default limit
};

export const useCreditsStore = create<CreditsStore>()(
  persist(
    (set, get) => ({
      credits: 0, // Default to 0, will be loaded from DB
      monthlyLimit: PLAN_MONTHLY_LIMITS.default,
      isCallActive: false,
      renewalDate: new Date(new Date().setDate(new Date().getDate() + 30)).toISOString(),

      // Get the monthly limit based on the user's plan
      getMonthlyLimit: () => {
        // This can be enhanced to get the user's actual plan from a plans table
        return PLAN_MONTHLY_LIMITS.default;
      },

      // Fetch user credits from Supabase
      fetchCredits: async () => {
        const user = useAuthStore.getState().user;
        if (!user) {
          console.warn('Cannot fetch credits: No authenticated user');
          return;
        }

        try {
          console.log('Fetching credits for user:', user.email);
          const { data, error } = await supabase
            .from('users')
            .select('credits')
            .eq('email', user.email)
            .single();

          if (error) {
            console.error('Error fetching credits:', error);
            return;
          }

          if (data) {
            const monthlyLimit = get().getMonthlyLimit();
            console.log('Credits fetched successfully:', data.credits);
            set({ 
              credits: data.credits, 
              monthlyLimit 
            });

            // Update user in auth store with correct credit info
            useAuthStore.setState({
              user: { ...user, credits: data.credits }
            });
          }
        } catch (err) {
          console.error('Exception in fetchCredits:', err);
        }
      },

      // Deduct credits and update in DB
      deductCredits: async (totalAmount: number) => {
        console.log('deductCredits function triggered with amount:', totalAmount);
        
        const user = useAuthStore.getState().user;
        if (!user) {
          console.error('No authenticated user. Cannot deduct credits.');
          return;
        }
        
        if (!user.id) {
          console.error('User ID is missing. Cannot deduct credits.');
          return;
        }
        
        try {
          // First get the current credits from DB to ensure we have the latest value
          const { data: currentData, error: fetchError } = await supabase
            .from('users')
            .select('credits')
            .eq('id', user.id)
            .single();
            
          if (fetchError) {
            console.error('Error fetching current credits:', fetchError);
            return;
          }
          
          const currentCredits = currentData?.credits || 0;
          console.log('Current credits in DB:', currentCredits);
          
          // Calculate new credits balance, ensuring it doesn't go below zero
          const newCredits = Math.max(0, currentCredits - totalAmount);
          console.log(`Deducting ${totalAmount} credits. New balance: ${newCredits}`);
          
          // Update local state
          set({ credits: newCredits });
          useAuthStore.setState({ 
            user: { ...user, credits: newCredits } 
          });
          
          // Update database
          const { data, error } = await supabase
            .from('users')
            .update({ credits: newCredits })
            .eq('id', user.id)
            .select();
            
          if (error) {
            console.error('Database update error:', error);
          } else {
            console.log('Database credits updated successfully:', data);
          }
        } catch (err) {
          console.error('Exception in deductCredits:', err);
        }
      },

      setCallActive: (active) => set({ isCallActive: active }),
    }),
    {
      name: 'credits-storage',
    }
  )
);