import React, { useEffect, useState, useCallback } from 'react';
import { useAuthStore } from '../../store/useAuthStore';
import { useNavigate, useLocation } from 'react-router-dom';
import { Loader2 } from 'lucide-react';
import supabase from '../../config/supabaseClient';

export function OTPVerification() {
  const { loading, verifyOTP } = useAuthStore();
  const navigate = useNavigate();
  const location = useLocation();
  const [verificationError, setVerificationError] = useState<string | null>(null);
  const [verificationAttempted, setVerificationAttempted] = useState(false);
  const [storedToken, setStoredToken] = useState<string | null>(null);

  const handleVerification = useCallback(async (token: string) => {
    try {
      console.log('Starting verification with token...');
      
      // Get the current session
      const { data: { session: currentSession } } = await supabase.auth.getSession();
      console.log('Current session:', currentSession?.user?.email);

      if (!currentSession) {
        // If no session, try to get one from the token
        console.log('No current session, attempting to exchange token...');
        const { data: { session }, error } = await supabase.auth.getSession();
        
        if (error || !session) {
          console.error('Failed to get session:', error);
          throw new Error('Failed to establish session');
        }
      }

      // Now we should have a valid session
      const { data: { session }, error: sessionError } = await supabase.auth.getSession();
      
      if (sessionError || !session?.user?.email) {
        console.error('Session error:', sessionError);
        throw new Error('Invalid session');
      }

      console.log('Proceeding with verification for email:', session.user.email);
      const isNewUser = await verifyOTP(session.user.email, token, navigate);
      
      if (isNewUser) {
        navigate('/complete-profile');
      } else {
        navigate('/app/buyers'); // Ensure this path is correct
      }
    } catch (error) {
      console.error('Verification failed:', error);
      setVerificationError('Verification failed. Please request a new magic link.');
      navigate('/auth');
    }
  }, [navigate, verifyOTP]);

  useEffect(() => {
    if (verificationAttempted || storedToken) {
      return;
    }

    const hashParams = new URLSearchParams(location.hash.substring(1));
    const token = hashParams.get('access_token');

    if (token) {
      console.log('Token found in URL, storing...');
      setStoredToken(token);
      handleVerification(token);
      setVerificationAttempted(true);
    } else if (!verificationAttempted) {
      console.log('No token found in URL');
      setVerificationError('Invalid or missing token. Please try again.');
      navigate('/auth');
      setVerificationAttempted(true);
    }
  }, [location, handleVerification, verificationAttempted, navigate, storedToken]);

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="text-center">
        {verificationError ? (
          <>
            <h2 className="text-2xl font-bold text-gray-900">Verification Failed</h2>
            <p className="mt-2 text-gray-600">{verificationError}</p>
            <button
              onClick={() => navigate('/auth')}
              className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
              Go to Login
            </button>
          </>
        ) : (
          <>
            <Loader2 className="animate-spin h-12 w-12 text-blue-600 mb-4" />
            <h2 className="text-2xl font-bold text-gray-900">Verifying Sign-in Link...</h2>
            <p className="mt-2 text-gray-600">Please wait while we verify your sign-in link.</p>
          </>
        )}
      </div>
    </div>
  );
}