import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { Sidebar } from './components/Sidebar';
import { LandingPage } from './pages/LandingPage';
import { BuyersPage } from './pages/BuyersPage';
import { LeadsPage } from './pages/LeadsPage';
import { SettingsPage } from './pages/SettingsPage';
import { CallHistoryPage } from './pages/CallHistoryPage';
import { RegisterPage } from './pages/RegisterPage';
import { CallDetailsPage } from './pages/CallDetailsPage';
import { LiveCallPage } from './pages/LiveCallPage';
import { AuthPage } from './pages/AuthPage';
import { VerifyEmailPage } from './pages/VerifyEmailPage';
import { WelcomePage } from './pages/WelcomePage';
import { CompleteProfile } from './components/auth/CompleteProfile';
import { OTPVerification } from './components/auth/OTPVerification';
import { useAuthStore } from './store/useAuthStore';

function PrivateRoute({ children }: { children: React.ReactNode }) {
  const { user, isInitialized } = useAuthStore();

  // Wait for the session check to complete
  if (!isInitialized) {
    return <div>Loading...</div>; // Show a loading spinner while checking the session
  }

  // Allow access to OTP verification and complete profile pages even if the user is not authenticated
  if (!user && !window.location.pathname.startsWith('/otp-verification') && !window.location.pathname.startsWith('/complete-profile')) {
    return <Navigate to="/auth" replace />;
  }

  return <>{children}</>;
}

export default function App() {
  const { checkSession } = useAuthStore();

  // Check the session on app load
  useEffect(() => {
    checkSession();
  }, [checkSession]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/auth" element={<AuthPage />} />
        <Route path="/verify-email" element={<VerifyEmailPage />} />
        <Route path="/otp-verification" element={<OTPVerification />} />
        <Route path="/complete-profile" element={<CompleteProfile />} />
        <Route path="/welcome" element={<WelcomePage />} />
        <Route path="/register" element={<RegisterPage />} />
        
        <Route
          path="/app/*"
          element={
            <PrivateRoute>
              <div className="flex min-h-screen bg-gray-100">
                <Sidebar />
                <main className="flex-1 overflow-auto">
                  <Routes>
                    <Route path="/" element={<Navigate to="/app/buyers" replace />} />
                    <Route path="/buyers" element={<BuyersPage />} />
                    <Route path="/calls" element={<LiveCallPage />} />
                    <Route path="/history" element={<CallHistoryPage />} />
                    <Route path="/history/:callId" element={<CallDetailsPage />} />
                    <Route path="/settings" element={<SettingsPage />} />
                  </Routes>
                </main>
              </div>
            </PrivateRoute>
          }
        />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </BrowserRouter>
  );
}