import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Filter, Search } from "lucide-react";
import { motion } from "framer-motion";
import { VAPI_CONFIG, VAPI_API_KEY } from "../components/VapiConstants";
import  supabase  from "../config/supabaseClient"; // Assuming you have a supabase client setup

interface CallDetail {
  callId: string;
  assistantId: string;
  startedAt: string;
  endedAt: string;
  callEndReason: string;
}

const fetchCallDetailsForAssistant = async (assistantId: string, userId: string): Promise<CallDetail[]> => {
  const token = VAPI_API_KEY.PRIVATE_API_KEY;
  const apiUrl = `https://api.vapi.ai/call`;

  const options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  try {
    // Fetch all calls from VAPI
    const response = await fetch(apiUrl, options);
    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }
    const calls = await response.json();

    // Fetch call IDs associated with the current user from Supabase
    const { data: userCalls, error } = await supabase
      .from('user_calls')
      .select('call_id')
      .eq('user_id', userId); // Use the authenticated user's ID

    if (error) {
      throw error;
    }

    // Filter calls to only include those associated with the current user
    const userCallIds = userCalls.map((uc: any) => uc.call_id);
    const filteredCalls = calls.filter((call: any) => userCallIds.includes(call.id));

    return filteredCalls.map((call: any) => ({
      callId: call.id,
      assistantId: call.assistantId,
      startedAt: call.startedAt,
      endedAt: call.endedAt,
      callEndReason: call.endedReason || "Unknown",
    }));
  } catch (error) {
    console.error("Error fetching call details:", error);
    return [];
  }
};

const formatCallEndReason =(callEndReason:string):string=>{
  switch(callEndReason){
    case "customer-ended-call":
      return "Customer Ended Call";
    case "customer-did-not-give-microphone-permission":
      return "No Microphone Permission"
    default:
      return "Unknown"
  }
}

const calculateDuration = (startedAt: string, endedAt: string): string => {
  const start = new Date(startedAt).getTime();
  const end = new Date(endedAt).getTime();
  const durationInMs = end - start;
  const minutes = Math.floor(durationInMs / 60000);
  const seconds = Math.floor((durationInMs % 60000) / 1000);
  if(minutes==0){
    return `${seconds} sec`;
  }else{
    return `${minutes} min ${seconds} sec`;
  }
  
};

const formatDate = (dateString: string): string => {
  const date = new Date(dateString);
  return new Intl.DateTimeFormat('en-US', {
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  }).format(date);
};

const getAssistantDetails = (assistantId: string) => {
  return VAPI_CONFIG.find((assistant) => assistant.ASSISTANT_ID === assistantId);
};

export function CallHistoryPage() {
  const navigate = useNavigate();
  const [callDetails, setCallDetails] = useState<CallDetail[]>([]); 
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1); 

  useEffect(() => {
    async function fetchData() {
      const { data: { user: currentUser } } = await supabase.auth.getUser(); // Get the authenticated user
      if (currentUser) {
        const data = await fetchCallDetailsForAssistant(
          VAPI_CONFIG[0].ASSISTANT_ID,
          currentUser.id // Use the authenticated user's ID
        );
        setCallDetails(data);
      }
    }
    fetchData();
  }, []);

  const handleRowClick = (callId: string) => {
    navigate(`/app/history/${callId}`);
  };

  const startIndex = (currentPage - 1) * rowsPerPage;
  const currentCalls = callDetails.slice(startIndex, startIndex + rowsPerPage);
  const totalPages = Math.ceil(callDetails.length / rowsPerPage);

  return (
    <div className="p-6">
      <div className="bg-white rounded-xl shadow-sm">
        <div className="p-4 border-b border-gray-200">
          <div className="flex items-center justify-between mb-4">
            <h1 className="text-2xl font-bold text-gray-900">Call History</h1>
            <motion.button
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
            >
              Export Data
            </motion.button>
          </div>

          <div className="flex items-center space-x-4">
            <button className="flex items-center space-x-2 px-3 py-1.5 bg-gray-100 rounded-lg text-gray-700 hover:bg-gray-200">
              <Filter size={16} />
              <span>Filter</span>
            </button>

            <div className="flex items-center px-3 py-1.5 bg-gray-100 rounded-lg flex-1 max-w-md">
              <Search size={16} className="text-gray-400 mr-2" />
              <input
                type="text"
                placeholder="Search calls..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="bg-transparent border-none focus:ring-0 flex-1 text-sm"
              />
            </div>
          </div>
        </div>

        <div className="overflow-x-auto">
          <table className="w-full">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Call ID
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Call End Reason
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Assistant Name
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Start Time
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Duration
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {currentCalls.map((call) => {
                const assistant = getAssistantDetails(call.assistantId);
                return (
                  <tr
                    key={call.callId}
                    className="hover:bg-gray-50 cursor-pointer"
                    onClick={() => handleRowClick(call.callId)}
                  >
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {call.callId}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {formatCallEndReason(call.callEndReason)}
                    </td>
                    
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 flex items-center space-x-2">
                      {assistant ? (
                        <>
                          <img
                            src={assistant.AGENT_IMAGE}
                            alt={assistant.AGENT_NAME}
                            className="w-8 h-8 rounded-full"
                          />
                          <span>{assistant.AGENT_NAME}</span>
                        </>
                      ) : (
                        "Unknown"
                      )}
                    </td>

                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {formatDate(call.startedAt)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {calculateDuration(call.startedAt, call.endedAt)}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        {/* Pagination controls */}
        <div className="px-6 py-4 flex items-center justify-between border-t border-gray-200">
          <div className="flex items-center">
            <span className="text-sm text-gray-700">Rows per page:</span>
            <select
              value={rowsPerPage}
              onChange={(e) => setRowsPerPage(Number(e.target.value))}
              className="ml-2 border-gray-300 rounded-md text-sm"
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
            </select>
          </div>

          <div className="flex items-center space-x-4">
            <button
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              className="px-4 py-2 bg-gray-100 text-gray-700 rounded-lg"
            >
              Previous
            </button>
            <span className="text-sm text-gray-700">
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
              className="px-4 py-2 bg-gray-100 text-gray-700 rounded-lg"
            >
              Next
            </button>
          </div>
        </div> 
      </div>
    </div>
  );
}
