import React, { useState } from 'react';
import { Mail, Loader2 } from 'lucide-react';
import { motion } from 'framer-motion';
import { useAuthStore } from '../store/useAuthStore';
import { useNavigate } from 'react-router-dom';
import supabase from '../config/supabaseClient';

export function AuthPage() {
  const [email, setEmail] = useState('');
  const { loading, error, sendOTP } = useAuthStore();
  const navigate = useNavigate();

  const handleSendOTP = async (e: React.FormEvent) => {
    e.preventDefault();

    // Admin bypass logic
    if (email === 'rraj@growthpods.io') {
      try {
        // Set loading state
        useAuthStore.getState().setLoading(true);
        
        // First, check if admin user exists in the database
        const { data: existingUser, error: userError } = await supabase
          .from('users')
          .select('*')
          .eq('email', 'rraj@growthpods.io')
          .single();
          
        let adminUser;
        
        if (userError || !existingUser) {
          console.log('Admin user not found, creating a mock admin user');
          
          // Create a mock admin user with a proper ID
          // Generate a UUID for admin if one doesn't exist
          const adminId = "admin-" + Math.random().toString(36).substring(2, 15);
          
          adminUser = {
            id: adminId, // Use string ID instead of number for compatibility
            first_name: 'Raj',
            last_name: 'Admin',
            email: 'rraj@growthpods.io',
            credits: 600,
          };
          
          // Optionally insert admin user into database for persistence
          const { error: insertError } = await supabase
            .from('users')
            .upsert([adminUser]);
            
          if (insertError) {
            console.error('Error creating admin user:', insertError);
          }
        } else {
          console.log('Admin user found in database');
          adminUser = existingUser;
        }

        // Create a custom session for admin
        // This is needed to ensure the admin user has the same capabilities as authenticated users
        const { data: sessionData, error: sessionError } = await supabase.auth.signInWithPassword({
          email: 'rraj@growthpods.io',
          password: 'admin-password', // Note: This won't actually authenticate with Supabase unless set up, 
                                       // but helps maintain session structure
        }).catch(() => {
          // If password auth fails (expected), try passwordless
          return supabase.auth.signInWithOtp({
            email: 'rraj@growthpods.io',
            options: { shouldCreateUser: false }
          });
        });
        
        if (sessionError) {
          console.warn('Admin session creation issue:', sessionError);
          // Continue anyway since we're bypassing normal auth
        } else {
          console.log('Admin session created successfully');
        }

        // Set admin user in store
        useAuthStore.getState().setUser(adminUser); 
        useAuthStore.getState().setLoading(false);
        
        // Redirect to admin page
        navigate('/app/buyers'); 
      } catch (error) {
        console.error('Error in admin bypass:', error);
        useAuthStore.getState().setLoading(false);
        useAuthStore.getState().setError('Admin authentication failed');
      }
      return;
    }

    // Regular user flow: Send OTP
    try {
      await sendOTP(email);
      navigate('/otp-verification', { state: { email } });
    } catch (error) {
      // Error is handled by the store
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="w-full max-w-md p-6 bg-white rounded-lg shadow-lg">
        <div className="text-center mb-8">
          <img
            src="/alex.png"
            alt="AiCoach Logo"
            className="w-32 h-18 mx-auto mb-4 object-cover"
          />
          <p className="mt-2 text-gray-600">
            Enter your email to receive a magic link for authentication
          </p>
        </div>

        {error && (
          <div className="mb-4 p-4 bg-red-50 text-red-700 rounded-lg text-sm">
            {error}
          </div>
        )}

        <form onSubmit={handleSendOTP} className="space-y-6">
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
              Email
            </label>
            <div className="mt-1 relative">
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full px-4 py-2 pl-10 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                placeholder="you@company.com"
                required
              />
              <Mail className="absolute left-3 top-2.5 text-gray-400" size={20} />
            </div>
          </div>

          <motion.button
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            type="submit"
            disabled={loading}
            className="w-full bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center space-x-2"
          >
            {loading ? (
              <Loader2 className="animate-spin" size={20} />
            ) : (
              <Mail size={20} />
            )}
            <span>Send Magic Link</span>
          </motion.button>
        </form>

        <p className="mt-4 text-center text-sm text-gray-600">
          Don't have an account?{' '}
          <button
            onClick={() => navigate('/register')}
            className="text-blue-600 hover:text-blue-700"
          >
            Sign up
          </button>
        </p>
      </div>
    </div>
  );
}