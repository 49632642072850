import { create } from 'zustand';
import supabase from '../config/supabaseClient';
import type { AuthState, User, UserRole } from '../types/auth';
import type { NavigateFunction } from 'react-router-dom';

const ADMIN_EMAIL = 'rraj@growthpods.io';

const debugLog = (context: string, message: string, data?: any) => {
  console.log(`[Auth Store][${context}]`, message, data || '');
};

export const useAuthStore = create<AuthState>((set) => ({
  user: null,
  loading: false,
  error: null,
  isInitialized: false,

  setUser: (user) => set({ user }),
  setLoading: (loading) => set({ loading }),
  setError: (error) => set({ error }),
  setIsInitialized: (isInitialized) => set({ isInitialized }),

  // Add the checkSession function
  checkSession: async () => {
    try {
      set({ loading: true, error: null });

      // Get the current session
      const { data: { session }, error } = await supabase.auth.getSession();

      if (error) {
        throw new Error('Error retrieving session');
      }

      // Special case for admin: check if admin user exists in local storage
      const storedAdmin = localStorage.getItem('admin_user');
      if (storedAdmin) {
        try {
          const adminUser = JSON.parse(storedAdmin);
          if (adminUser.email === ADMIN_EMAIL) {
            // Admin found in local storage
            debugLog('checkSession', 'Admin user found in local storage', adminUser);
            set({ user: adminUser, loading: false, isInitialized: true });
            return;
          }
        } catch (e) {
          console.error('Error parsing admin data:', e);
          // Continue with normal flow
        }
      }

      // No session and not admin
      if (!session) {
        set({ user: null, loading: false, isInitialized: true });
        return;
      }

      // Fetch user data from the database
      const { data: existingUser, error: userError } = await supabase
        .from('users')
        .select('*')
        .eq('email', session.user.email)
        .single();

      if (userError || !existingUser) {
        throw new Error('User not found');
      }

      // Set the user in the store
      set({ user: existingUser, loading: false, isInitialized: true });
    } catch (error) {
      set({
        error: error instanceof Error ? error.message : 'Failed to check session',
        loading: false,
        isInitialized: true,
      });
    }
  },

  // Register a new user
  registerUser: async (firstName: string, lastName: string, email: string) => {
    try {
      set({ loading: true, error: null });

      // Check if user already exists
      const { data: existingUser, error: checkError } = await supabase
        .from('users')
        .select('*')
        .eq('email', email)
        .single();

      if (existingUser) {
        throw new Error('User already exists. Please log in.');
      }

      if (checkError && checkError.code !== 'PGRST116') {
        throw checkError;
      }

      // Create user in database
      const { error: dbError } = await supabase
        .from('users')
        .insert([{ first_name: firstName, last_name: lastName, email }]);

      if (dbError) throw dbError;

      set({ loading: false });
      return true; // Registration successful
    } catch (error) {
      set({ error: error instanceof Error ? error.message : 'Failed to register', loading: false });
      throw error;
    }
  },

  sendOTP: async (email: string) => {
    try {
      set({ loading: true, error: null });
  
      // Admin bypass
      if (email === ADMIN_EMAIL) {
        debugLog('sendOTP', 'Admin email detected, bypassing OTP');
        
        // Fetch admin user data from the database
        const { data: adminUser, error: adminError } = await supabase
          .from('users')
          .select('*')
          .eq('email', ADMIN_EMAIL)
          .single();
          
        let adminData;
        
        if (adminError || !adminUser) {
          // Create a unique ID for the admin user
          const adminId = "admin-" + Math.random().toString(36).substring(2, 15);
          
          // Create admin user if not found
          adminData = {
            id: adminId,
            first_name: 'Raj',
            last_name: 'Admin',
            email: ADMIN_EMAIL,
            credits: 600,
          };
          
          // Insert admin user into database for persistence
          const { error: insertError } = await supabase
            .from('users')
            .upsert([adminData]);
            
          if (insertError) {
            console.error('Error creating admin user:', insertError);
          }
        } else {
          adminData = adminUser;
        }
        
        // Store admin user data in localStorage for persistence
        localStorage.setItem('admin_user', JSON.stringify(adminData));
        
        // Set up a minimal auth session for admin
        await supabase.auth.signInWithOtp({
          email: ADMIN_EMAIL,
          options: { shouldCreateUser: false }
        }).catch(e => {
          console.warn('Admin auth session setup failed:', e);
          // Not critical, continue
        });
        
        set({ user: adminData, loading: false });
        
        // Redirect to the buyers page
        window.location.href = '/app/buyers';
        return;
      }
  
      // Regular user flow: Send OTP
      const { error } = await supabase.auth.signInWithOtp({
        email,
        options: {
          emailRedirectTo: `${window.location.origin}/otp-verification`,
        },
      });
  
      if (error) {
        throw error;
      }
  
      set({ loading: false });
    } catch (error) {
      set({
        error: error instanceof Error ? error.message : 'Failed to send OTP',
        loading: false,
      });
      throw error;
    }
  },

  verifyOTP: async (email: string, token: string, navigate?: NavigateFunction) => {
    try {
      set({ loading: true, error: null });
      debugLog('VerifyOTP', `Starting verification for email: ${email}`);
  
      // Admin bypass
      if (email === ADMIN_EMAIL) {
        debugLog('VerifyOTP', 'Admin email detected, bypassing verification');
  
        // Fetch admin user data from the database
        const { data: adminUser, error: adminError } = await supabase
          .from('users')
          .select('*')
          .eq('email', ADMIN_EMAIL)
          .single();
          
        let adminData;
        
        if (adminError || !adminUser) {
          // Create a unique ID for the admin user
          const adminId = "admin-" + Math.random().toString(36).substring(2, 15);
          
          // Create admin user if not found
          adminData = {
            id: adminId,
            first_name: 'Raj',
            last_name: 'Admin',
            email: ADMIN_EMAIL,
            credits: 600,
          };
          
          // Insert admin user into database for persistence
          const { error: insertError } = await supabase
            .from('users')
            .upsert([adminData]);
            
          if (insertError) {
            console.error('Error creating admin user:', insertError);
          }
        } else {
          adminData = adminUser;
        }
        
        // Store admin user data in localStorage for persistence
        localStorage.setItem('admin_user', JSON.stringify(adminData));
          
        set({ user: adminData, loading: false });
  
        // Redirect to the buyers page
        if (navigate) {
          navigate('/app/buyers');
        } else {
          window.location.href = '/app/buyers';
        }
        return false; // Indicate that this is not a new user
      }
  
      // Regular user flow: Verify OTP
      const { data: { session }, error: sessionError } = await supabase.auth.getSession();
      debugLog('VerifyOTP', 'Session check result:', { session, error: sessionError });
  
      if (sessionError || !session) {
        debugLog('VerifyOTP', 'No valid session found');
        throw new Error('Session not found');
      }
  
      debugLog('VerifyOTP', 'Querying users table');
      const { data: existingUser, error: userError } = await supabase
        .from('users')
        .select('*')
        .eq('email::text', email.trim())
        .maybeSingle();
  
      console.log('Query Debug:', {
        email: email.trim(),
        existingUser,
        userError,
      });
  
      if (userError) {
        debugLog('VerifyOTP', 'Database query error:', userError);
        throw userError;
      }
  
      if (!existingUser) {
        debugLog('VerifyOTP', 'No existing user found - new user flow');
        return true; // Indicate that this is a new user
      } else {
        debugLog('VerifyOTP', 'Existing user found - login flow');
        set({ user: existingUser, loading: false });
        
        if (navigate) {
          navigate('/app/buyers');
        } else {
          window.location.href = '/app/buyers';
        }
        return false; // Indicate that this is not a new user
      }
    } catch (error) {
      debugLog('VerifyOTP', 'Verification failed:', error);
      set({
        error: error instanceof Error ? error.message : 'Failed to verify OTP',
        loading: false,
      });
      throw error;
    }
  },

  logout: async () => {
    try {
      set({ loading: true, error: null });
      
      // Check if this is the admin user
      const { user } = useAuthStore.getState();
      if (user && user.email === ADMIN_EMAIL) {
        // Clear admin data from localStorage
        localStorage.removeItem('admin_user');
      }
      
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      set({ user: null, loading: false });
    } catch (error) {
      set({
        error: error instanceof Error ? error.message : 'Failed to logout',
        loading: false,
      });
      throw error;
    }
  },

  completeProfile: async (data: { firstName: string; lastName: string; role: UserRole }) => {
    try {
      set({ loading: true, error: null });
      const { data: session, error: sessionError } = await supabase.auth.getSession();

      if (sessionError || !session?.session?.user) {
        throw new Error('No authenticated user');
      }

      const userData: User = {
        id: 0, // This will be updated by the database
        first_name: data.firstName,
        last_name: data.lastName,
        email: session.session.user.email || '',
        credits: 0, // Credits will be handled by the database default
      };

      const { error } = await supabase
        .from('users')
        .upsert([userData], { onConflict: 'id' });

      if (error) throw error;
      set({ user: userData, loading: false });
    } catch (error) {
      set({
        error: error instanceof Error ? error.message : 'Failed to complete profile',
        loading: false,
      });
      throw error;
    }
  },
}));