import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Phone, ArrowRight } from 'lucide-react';
import { motion } from 'framer-motion';
import { useAuthStore } from '../store/useAuthStore';

const categories = [
  {
    id: 'sales',
    name: 'Sales',
    description: 'Master sales techniques with AI-powered buyer personas',
    personas: [
      {
        name: 'Sarah Rodriguez',
        title: 'CIO',
        company: 'BioLife Sciences',
        image: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?w=400',
        challenge: 'Evaluating new data management solutions'
      },
      {
        name: 'Michael Chen',
        title: 'VP of IT',
        company: 'RetailGiant Corp',
        image: 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?w=400',
        challenge: 'Seeking to improve data quality'
      },
      {
        name: 'Emma Thompson',
        title: 'Head of Digital',
        company: 'TechForward',
        image: 'https://images.unsplash.com/photo-1438761681033-6461ffad8d80?w=400',
        challenge: 'Digital transformation initiatives'
      }
    ]
  },
  {
    id: 'recruitment',
    name: 'Recruitment',
    description: 'Practice technical interviews and candidate assessments',
    personas: [
      {
        name: 'James Wilson',
        title: 'Technical Lead',
        company: 'CloudTech',
        image: 'https://images.unsplash.com/photo-1519085360753-af0119f7cbe7?w=400',
        challenge: 'System design interview'
      },
      {
        name: 'Lisa Kumar',
        title: 'DevOps Engineer',
        company: 'InnovateAI',
        image: 'https://images.unsplash.com/photo-1534528741775-53994a69daeb?w=400',
        challenge: 'Infrastructure discussion'
      },
      {
        name: 'David Park',
        title: 'Frontend Developer',
        company: 'WebFlow',
        image: 'https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?w=400',
        challenge: 'React performance optimization'
      }
    ]
  },
  {
    id: 'customer-service',
    name: 'Customer Service',
    description: 'Handle support scenarios and customer interactions',
    personas: [
      {
        name: 'Rachel Morgan',
        title: 'Product Owner',
        company: 'SaaS Corp',
        image: 'https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?w=400',
        challenge: 'Feature request discussion'
      },
      {
        name: 'Tom Anderson',
        title: 'IT Manager',
        company: 'Enterprise Ltd',
        image: 'https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?w=400',
        challenge: 'Technical support escalation'
      },
      {
        name: 'Maria Garcia',
        title: 'Operations Director',
        company: 'Global Systems',
        image: 'https://images.unsplash.com/photo-1573497019940-1c28c88b4f3e?w=400',
        challenge: 'Service upgrade consultation'
      }
    ]
  }
];

export function LandingPage() {
  const navigate = useNavigate();
  const { user } = useAuthStore();
  const [selectedCategory, setSelectedCategory] = React.useState(categories[0]);

  const handleStartCall = () => {
    if (user) {
      navigate('/app/calls');
    } else {
      navigate('/auth');
    }
  };

  return (
    <div className="min-h-screen bg-white">
    <header className="max-w-7xl mx-auto px-4 py-6 flex justify-between items-center border-b">
      {/* Logo Section */}
      <div className="flex items-center space-x-3">
        {/* Display only the image */}
        <img
          src="/alex.png" // Correct path (no `/public` prefix)
          alt="AiCoach Logo"
          className="w-15 h-10 object-cover" // Adjust size as needed
        />
      </div>
        <div className="flex items-center space-x-4">
          <button
            onClick={() => navigate('/auth')}
            className="text-gray-600 hover:text-blue-600 transition-colors"
          >
            {user ? 'Sign In' : 'Sign In'}
          </button>
          <button
            onClick={() => navigate('/register')}
            className="text-gray-600 hover:text-blue-600 transition-colors"
          >
            {user ? 'Sign Up' : 'Sign Up'}
          </button>
          <motion.button
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
          >
            Book a Demo
          </motion.button>
        </div>
      </header>

      <main className="max-w-7xl mx-auto px-4 py-12">
        <div className="text-center mb-12">
          <h2 className="text-4xl font-bold text-gray-900 mb-4">
            Boost Performance Across Departments with Persona-Driven AI Coaching
          </h2>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Elevate your team's effectiveness in sales, marketing, HR, recruitment, customer service, and education. Our AI Coach delivers tailored, real-world guidance, ensuring immediate improvements and measurable ROI.
          </p>
        </div>

        <div className="grid grid-cols-4 gap-8">
          <div className="space-y-4">
            {categories.map((category) => (
              <motion.button
                key={category.id}
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                onClick={() => setSelectedCategory(category)}
                className={`w-full p-4 rounded-lg text-left transition-colors ${
                  selectedCategory.id === category.id
                    ? 'bg-blue-50 border-2 border-blue-500'
                    : 'bg-gray-50 hover:bg-gray-100'
                }`}
              >
                <h3 className="font-semibold text-gray-900">{category.name}</h3>
                <p className="text-sm text-gray-600">{category.description}</p>
              </motion.button>
            ))}
          </div>

          <div className="col-span-3 grid grid-cols-3 gap-6">
            {selectedCategory.personas.map((persona) => (
              <motion.div
                key={persona.name}
                whileHover={{ scale: 1.02 }}
                className="bg-white rounded-xl p-6 shadow-lg"
              >
                <div className="relative mb-4">
                  <img
                    src={persona.image}
                    alt={persona.name}
                    className="w-20 h-20 rounded-full mx-auto object-cover"
                  />
                </div>
                <div className="text-center mb-4">
                  <h3 className="font-semibold text-gray-900">{persona.name}</h3>
                  <p className="text-sm text-gray-600">{persona.title}</p>
                  <p className="text-sm text-gray-500">{persona.company}</p>
                </div>
                <p className="text-sm text-gray-600 mb-4">
                  {persona.challenge}
                </p>
                <button
                  onClick={handleStartCall}
                  className="w-full bg-blue-600 hover:bg-blue-700 text-white rounded-lg py-2 flex items-center justify-center space-x-2 transition-colors"
                >
                  <Phone size={18} />
                  <span>Start Call</span>
                </button>
              </motion.div>
            ))}
          </div>
        </div>

        <div className="mt-24 text-center">
          <h2 className="text-3xl font-bold text-gray-900 mb-4">
            Ready to Revolutionize Your Team's Potential?
          </h2>
          <p className="text-xl text-gray-600 mb-8 max-w-3xl mx-auto">
            Empower your workforce or students with tailored AI coaching designed to drive results faster and smarter. Experience the future of learning and development today.
          </p>
          <motion.button
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            onClick={() => navigate('/auth')}
            className="inline-flex items-center space-x-2 px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
          >
            <span>Get Started Now</span>
            <ArrowRight size={20} />
          </motion.button>
        </div>

        <div className="mt-24 grid grid-cols-4 gap-4">
          {[
            'https://images.unsplash.com/photo-1600880292203-757bb62b4baf?w=800',
            'https://images.unsplash.com/photo-1522071820081-009f0129c71c?w=800',
            'https://images.unsplash.com/photo-1551434678-e076c223a692?w=800',
            'https://images.unsplash.com/photo-1600880292089-90a7e086ee0c?w=800'
          ].map((src, index) => (
            <div key={index} className="relative h-64 rounded-xl overflow-hidden">
              <img
                src={src}
                alt="Team collaboration"
                className="w-full h-full object-cover"
              />
            </div>
          ))}
        </div>
      </main>

      <footer className="bg-gray-900 text-white py-12 mt-24">
        <div className="max-w-7xl mx-auto px-4">
          <div className="grid grid-cols-4 gap-8">
            <div>
              <h3 className="font-bold mb-4">ProjectA.Ai</h3>
              <p className="text-gray-400 text-sm">
                Redefining Excellence Through AI
              </p>
            </div>
            <div>
              <h3 className="font-bold mb-4">Contact</h3>
              <p className="text-gray-400 text-sm">support@projecta.ai</p>
              <p className="text-gray-400 text-sm">+1 (123) 456-7890</p>
            </div>
            <div>
              <h3 className="font-bold mb-4">Follow Us</h3>
              <div className="space-y-2">
                <a href="#" className="block text-gray-400 hover:text-white">LinkedIn</a>
                <a href="#" className="block text-gray-400 hover:text-white">Twitter</a>
                <a href="#" className="block text-gray-400 hover:text-white">Facebook</a>
              </div>
            </div>
            <div>
              <h3 className="font-bold mb-4">Legal</h3>
              <div className="space-y-2">
                <a href="#" className="block text-gray-400 hover:text-white">Privacy Policy</a>
                <a href="#" className="block text-gray-400 hover:text-white">Terms of Service</a>
                <a href="#" className="block text-gray-400 hover:text-white">FAQs</a>
              </div>
            </div>
          </div>
          <div className="mt-12 pt-8 border-t border-gray-800 text-center text-gray-400">
            <p>© 2024 ProjectA.Ai. All Rights Reserved.</p>
            <p className="mt-2 text-sm">Built to Scale, Powered by AI.</p>
          </div>
        </div>
      </footer>
    </div>
  );
}