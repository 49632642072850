import React, { useState, useCallback } from 'react';
import { Dialog } from '@headlessui/react';
import { Phone, X, Mic, MicOff, Loader2 } from 'lucide-react';
import type { Buyer } from '../types';
import { VapiService } from '../services/vapi';
import { motion, AnimatePresence } from 'framer-motion';
import { CALL_STATUS, VAPI_CONFIG } from '../components/VapiConstants';
import { VapiWidget } from './VapiWidget';
import {getConfigByBuyerId} from '../components/VapiConstants';
import supabase from '../config/supabaseClient'; // Import Supabase client



interface BuyerDialogProps {
  isOpen: boolean;
  onClose: () => void;
  buyer: Buyer;
}

const initialCallState = {
  isActive: false,
  error: null as string | null,
  status: CALL_STATUS.IDLE,
};

export function BuyerDialog({ isOpen, onClose, buyer }: BuyerDialogProps) {
  const [callState, setCallState] = useState(initialCallState);
  const [isMuted, setIsMuted] = useState(false);
  const [transcript, setTranscript] = useState<Array<{ speaker: string, timestamp: string, text: string }>>([]);

  const getAssistantConfig = useCallback(() => {
    const config = VAPI_CONFIG.find(config => config.AGENT_NAME === buyer.name);
    if (!config) {
      throw new Error(`No configuration found for buyer: ${buyer.name}`);
    }
    return config;
  }, [buyer.name]);

  const updateCallState = useCallback((updates: Partial<typeof initialCallState>) => {
    setCallState((prev) => ({ ...prev, ...updates }));
  }, []);

  const handleStartCall = useCallback(async () => {
    try {
      const vapiService = VapiService.getInstance((transcript: string, speaker: string) => {
        setTranscript((prev) => [...prev, { speaker, timestamp: new Date().toISOString(), text: transcript }]);
      });
      if (!vapiService) throw new Error('VapiService not initialized');

      const config = getConfigByBuyerId(buyer.id);

      
      updateCallState({ status: CALL_STATUS.CONNECTING, error: null });
      const { data: { user: currentUser } } = await supabase.auth.getUser();
      if (!currentUser) throw new Error('User not authenticated');
      console.log('Current user:', currentUser);
      await vapiService.startCall(config.ASSISTANT_ID, currentUser.id);
      
      const currentTranscript = vapiService.getCurrentTranscript();
      updateCallState({ isActive: true, status: CALL_STATUS.ACTIVE });

      setTranscript((prev) => [...prev, { 
        speaker: config.AGENT_NAME, 
        timestamp: new Date().toISOString(), 
        text: currentTranscript 
      }]);

    } catch (error) {
      updateCallState({
        error: error instanceof Error ? error.message : 'Failed to start call',
        status: CALL_STATUS.ERROR,
      });
    }
  }, [updateCallState, getAssistantConfig]);

  const handleEndCall = useCallback(async () => {
    try {
      const vapiService = VapiService.getInstance(() => {});
      if (!vapiService) throw new Error('VapiService not initialized');

      await vapiService.stopCall();
      setCallState(initialCallState);
      setTranscript([]);
    } catch (error) {
      updateCallState({
        error: error instanceof Error ? error.message : 'Failed to end call',
        status: CALL_STATUS.ERROR,
      });
    }
  }, [updateCallState]);

  const toggleMute = () => {
    const vapiService = VapiService.getInstance(() => {});
    if (!vapiService) throw new Error('VapiService not initialized');

    const newMuteState = !isMuted;
    vapiService.toggleMute(); 
    setIsMuted(newMuteState); 
  };

  if (!isOpen) return null;

  const getRoleplayInstructions = (buyer: Buyer) => {
    switch (buyer.name) {
      case 'Sarah Rodriguez':
        return "Sarah is evaluating new biotech data management solutions. Engage with her about her current challenges and demonstrate how your solution can streamline their research data workflows.";
      case 'Michael Chen':
        return "Michael's retail chain is experiencing data quality issues with their current vendor. Show him how your solution can improve their customer data accuracy and compliance.";
      default:
        return `${buyer.name} is looking for solutions to improve their data management. Understand their needs and present your solution.`;
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose} className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen">
        <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-50" />
        
        <AnimatePresence>
          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            className="relative bg-white rounded-xl max-w-2xl w-full mx-4 p-6"
          >
            <button
              onClick={onClose}
              className="absolute right-4 top-4 text-gray-400 hover:text-gray-600"
            >
              <X size={24} />
            </button>

            {callState.isActive ? (
              <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
                <div className="bg-white rounded-xl p-6 w-full max-w-4xl">
                  <div className="flex justify-between items-start mb-6">
                    <div className="flex items-center space-x-4">
                      <img
                        src={buyer.avatar}
                        alt={buyer.name}
                        className="w-16 h-16 rounded-full object-cover"
                      />
                      <div>
                        <h3 className="text-xl font-semibold">{buyer.name}</h3>
                        <p className="text-gray-600">{buyer.title} @ {buyer.company}</p>
                      </div>
                    </div>
                  </div>

                  {callState.status === CALL_STATUS.ERROR ? (
                    <div className="text-center p-8">
                      <h3 className="text-xl font-semibold text-red-600 mb-4">Call Error</h3>
                      <p className="text-gray-600">{callState.error}</p>
                      <button
                        onClick={onClose}
                        className="bg-gray-500 text-white py-2 px-6 rounded-lg hover:bg-gray-600"
                      >
                        Close
                      </button>
                    </div>
                  ) : (
                    <>
                      {callState.status === CALL_STATUS.CONNECTING ? (
                        <div className="text-center py-12">
                          <Loader2 className="w-12 h-12 animate-spin text-blue-600 mx-auto mb-4" />
                          <p className="text-gray-600">Connecting to call...</p>
                        </div>
                      ) : (
                        <div className="space-y-6">
              <div className="bg-gray-50 p-4 rounded-lg">
                <h4 className="font-semibold mb-2">Live Transcript</h4>
                <div className="min-h-[400px] max-h-[400px] overflow-y-auto space-y-2">
                  {transcript.length > 0 ? (
                    transcript.map((item, index) => (
                      <div key={index} className="p-2 bg-white rounded">
                        <div className="flex justify-between text-xs text-gray-500 mb-1">
                          <span>{item.speaker}</span>
                          <span>{new Date(item.timestamp).toLocaleTimeString()}</span>
                        </div>
                        <p className="text-gray-900">{item.text}</p>
                      </div>
        ))
      ) : (
        <p className="text-gray-500">Waiting for speech...</p>
      )}
    </div>
  </div>


                          <div className="flex justify-center space-x-4">
                            <button
                              onClick={toggleMute}
                              className={`p-3 rounded-full ${isMuted ? 'bg-red-100 text-red-600' : 'bg-gray-100 text-gray-600'}`}
                            >
                              {isMuted ? <MicOff size={24} /> : <Mic size={24} />}
                            </button>
                            <button
                              onClick={handleEndCall}
                              className="bg-red-500 text-white py-2 px-6 rounded-lg hover:bg-red-600 flex items-center space-x-2"
                            >
                              <Phone size={20} />
                              <span>End Call</span>
                            </button>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            ) : (
              <div>
                <div className="flex items-center space-x-6 mb-8">
                  <img
                    src={buyer.avatar}
                    alt={buyer.name}
                    className="w-24 h-24 rounded-full object-cover"
                  />
                  <div>
                    <h2 className="text-2xl font-bold text-gray-900">{buyer.name}</h2>
                    <p className="text-lg text-gray-600">{buyer.title}</p>
                    <p className="text-gray-600">{buyer.company}</p>
                  </div>
                </div>

                <div className="mb-8">
                  <h3 className="text-lg font-semibold text-gray-900 mb-3">
                    AI Roleplay Instructions
                  </h3>
                  <p className="text-gray-700 bg-gray-50 p-4 rounded-lg">
                    {getRoleplayInstructions(buyer)}
                  </p>
                </div>

                <div className="mb-6">
                  <h3 className="text-lg font-semibold text-gray-900 mb-3">
                    Buyer Traits
                  </h3>
                  <div className="flex flex-wrap gap-2">
                    {buyer.personality.map((trait) => (
                      <span
                        key={trait}
                        className="px-3 py-1 bg-blue-100 text-blue-800 rounded-full text-sm"
                      >
                        {trait}
                      </span>
                    ))}
                  </div>
                </div>

                <button
                  onClick={handleStartCall}
                  className="w-full bg-blue-600 text-white py-3 px-6 rounded-xl hover:bg-blue-700 transition-colors flex items-center justify-center space-x-2"
                >
                  <Phone size={20} />
                  <span>Start Call</span>
                </button>
              </div>
            )}
          </motion.div>
        </AnimatePresence>
      </div>
    </Dialog>
  );
}
