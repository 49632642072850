import React, { useState } from 'react';
import { MoreVertical, TrendingUp, Clock, Users, DollarSign, Heart, Timer, Star, GraduationCap, UserCheck } from 'lucide-react';
import { motion } from 'framer-motion';
import type { Buyer } from '../types';
import { BuyerDialog } from './BuyerDialog';

interface BuyerCardProps {
  buyer: Buyer;
  onSelect: (buyer: Buyer) => void;
  onHide: () => void; // Function to hide the buyer
}

const countryFlags: { [key: string]: string } = {
  'USA': 'https://alexsdr.netlify.app/assets/flag-CurXN16Q.png',
  'India': 'https://alexsdr.netlify.app/assets/India-EJr_l_3Q.png',
  'European Union': 'https://alexsdr.netlify.app/assets/european-rrs3Pzaf.png',
  'Australia': 'https://alexsdr.netlify.app/assets/australia-CmGaS8HA.png',
};

export function BuyerCard({ buyer, onSelect, onHide }: BuyerCardProps) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State for dropdown menu

  const handleCardClick = () => {
    onSelect(buyer);
    setIsDialogOpen(true);
  };

  const flagUrl = countryFlags[buyer.country] || null;

  const renderMetrics = () => {
    const { metrics } = buyer;
    
    if (buyer.title.includes('CIO') || buyer.title.includes('VP')) {
      return (
        <>
          <div className="flex items-center space-x-1">
            <Clock size={16} className="text-blue-500" />
            <span className="text-sm font-medium text-blue-700">
              {metrics.decisionTime} Decision Time
            </span>
          </div>
          <div className="flex items-center space-x-1">
            <DollarSign size={16} className="text-green-500" />
            <span className="text-sm font-medium text-green-700">
              {metrics.budgetRange} Budget
            </span>
          </div>
          <div className="flex items-center space-x-1">
            <Users size={16} className="text-purple-500" />
            <span className="text-sm font-medium text-purple-700">
              {metrics.stakeholders} Stakeholders
            </span>
          </div>
        </>
      );
    }

    if (buyer.title.includes('HR')) {
      return (
        <>
          <div className="flex items-center space-x-1">
            <Timer size={16} className="text-blue-500" />
            <span className="text-sm font-medium text-blue-700">
              {metrics.timeToHire} Time to Hire
            </span>
          </div>
          <div className="flex items-center space-x-1">
            <Heart size={16} className="text-red-500" />
            <span className="text-sm font-medium text-red-700">
              {metrics.retentionRate} Retention
            </span>
          </div>
          <div className="flex items-center space-x-1">
            <Star size={16} className="text-yellow-500" />
            <span className="text-sm font-medium text-yellow-700">
              {metrics.employeeNPS} NPS
            </span>
          </div>
        </>
      );
    }

    if (buyer.title.includes('Support')) {
      return (
        <>
          <div className="flex items-center space-x-1">
            <Timer size={16} className="text-blue-500" />
            <span className="text-sm font-medium text-blue-700">
              {metrics.responseTime} Response
            </span>
          </div>
          <div className="flex items-center space-x-1">
            <Star size={16} className="text-yellow-500" />
            <span className="text-sm font-medium text-yellow-700">
              {metrics.satisfactionScore}/5 CSAT
            </span>
          </div>
          <div className="flex items-center space-x-1">
            <Heart size={16} className="text-red-500" />
            <span className="text-sm font-medium text-red-700">
              {metrics.resolutionRate} Resolution
            </span>
          </div>
        </>
      );
    }

    if (buyer.title.includes('Education')) {
      return (
        <>
          <div className="flex items-center space-x-1">
            <GraduationCap size={16} className="text-blue-500" />
            <span className="text-sm font-medium text-blue-700">
              {metrics.studentSuccess} Success Rate
            </span>
          </div>
          <div className="flex items-center space-x-1">
            <Star size={16} className="text-yellow-500" />
            <span className="text-sm font-medium text-yellow-700">
              {metrics.satisfactionRate}/5 Rating
            </span>
          </div>
          <div className="flex items-center space-x-1">
            <UserCheck size={16} className="text-green-500" />
            <span className="text-sm font-medium text-green-700">
              {metrics.programCompletion} Completion
            </span>
          </div>
        </>
      );
    }

    if (buyer.title.includes('Recruiter')) {
      return (
        <>
          <div className="flex items-center space-x-1">
            <UserCheck size={16} className="text-blue-500" />
            <span className="text-sm font-medium text-blue-700">
              {metrics.offerAcceptRate} Acceptance
            </span>
          </div>
          <div className="flex items-center space-x-1">
            <Timer size={16} className="text-green-500" />
            <span className="text-sm font-medium text-green-700">
              {metrics.timeToOffer} to Offer
            </span>
          </div>
          <div className="flex items-center space-x-1">
            <Star size={16} className="text-yellow-500" />
            <span className="text-sm font-medium text-yellow-700">
              {metrics.candidateNPS} NPS
            </span>
          </div>
        </>
      );
    }

    return null;
  };

  return (
    <>
      <motion.div 
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.98 }}
        className="relative bg-white rounded-lg shadow-md p-6 hover:shadow-lg transition-all cursor-pointer"
        onClick={handleCardClick}
      >
        <div className="flex items-start justify-between mb-4">
          <div className="flex items-center space-x-4">
            <div className="relative">
              <img
                src={buyer.avatar}
                alt={buyer.name}
                className="w-12 h-12 rounded-full object-cover"
              />
              {/* Status indicator moved to bottom-right */}
              <div 
                className="absolute bottom-0 right-0 w-3 h-3 rounded-full border-2 border-white bg-green-500"
              />
            </div>
            <div>
              <h3 className="font-semibold text-gray-900">{buyer.name}</h3>
              <p className="text-sm text-gray-600">
                {buyer.title} @ {buyer.company}
              </p>
            </div>
          </div>
          <div className="relative">
            <button 
              className="text-gray-400 hover:text-gray-600"
              onClick={(e) => {
                e.stopPropagation(); // Prevent card click event
                setIsMenuOpen(!isMenuOpen); // Toggle dropdown menu
              }}
            >
              <MoreVertical size={20} />
            </button>
            {/* Dropdown Menu */}
            {isMenuOpen && (
              <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg z-10">
                <button
                  className="w-full px-4 py-2 text-sm text-red-600 hover:bg-red-50 rounded-lg"
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent card click event
                    onHide(); // Call the onHide function
                    setIsMenuOpen(false); // Close the menu
                  }}
                >
                  Delete Buyer
                </button>
              </div>
            )}
          </div>
        </div>

        <div className="space-y-3">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-1">
              <TrendingUp size={16} className="text-blue-500" />
              <span className="text-sm font-medium text-blue-700">
                {buyer.metrics.successRate}% Success Rate
              </span>
            </div>
            <span className="px-2 py-1 bg-blue-50 text-blue-700 text-xs font-medium rounded-full">
              {buyer.callType}
            </span>
          </div>

          <div className="space-y-2">
            {renderMetrics()}
          </div>

          <div className="flex flex-wrap gap-2">
            {buyer.personality.map((trait) => (
              <span
                key={trait}
                className="px-2 py-1 bg-gray-100 text-gray-700 text-xs font-medium rounded-full"
              >
                {trait}
              </span>
            ))}
          </div>
          {/* Country Flag - Positioned with margin and no shadow */}
          {flagUrl && (
            <img
              src={flagUrl}
              alt={`${buyer.country} Flag`}
              className="absolute bottom-4 right-4 w-6 h-6 rounded-full border border-gray-300 object-contain"
            />
          )}
        </div>
      </motion.div>

      <BuyerDialog
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        buyer={buyer}
      />
    </>
  );
}