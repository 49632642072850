export const VAPI_CONFIG = [
  // Sales Personas
  {
    ASSISTANT_ID: 'dde4e11c-b6c8-4da8-8790-5980ff0bca2c', 
    AGENT_NAME: 'Sarah Rodriguez',
    AGENT_IMAGE: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?w=400',
    BUYER_ID: '1',
    ROLE: 'CIO',
    COMPANY: 'BioLife Sciences'
  },
  {
    ASSISTANT_ID: '2785b2a0-f696-48d0-a375-6aa480883912', 
    AGENT_NAME: 'Michael Chen',
    AGENT_IMAGE: 'https://images.unsplash.com/photo-1560250097-0b93528c311a?w=400&h=400&fit=crop',
    BUYER_ID: '2',
    ROLE: 'VP of IT',
    COMPANY: 'RetailGiant Corp'
  },
  // HR Personas
  {
    ASSISTANT_ID: '050019c8-ee2c-45ce-a1e0-3939b03ba6cd', // Existing ID for Emma
    AGENT_NAME: 'Emma Thompson',
    AGENT_IMAGE: 'https://images.unsplash.com/photo-1438761681033-6461ffad8d80?w=400',
    BUYER_ID: '3',
    ROLE: 'Head of Digital',
    COMPANY: 'TechForward Solutions'
  },
  // Customer Service Personas
  // ###################################################################################################33#
  {
    ASSISTANT_ID: 'f4a12b56-d789-4e23-9abc-def012345678', // New ID for David
    AGENT_NAME: 'David Cooper',
    AGENT_IMAGE: 'https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?w=400',
    BUYER_ID: '4',
    ROLE: 'Support Team Lead',
    COMPANY: 'ServiceFirst Inc'
  },
  // Education Personas
  {
    ASSISTANT_ID: '6bc58310-12dd-45ff-ab0c-53e800a40e79', // New ID for Sophie
    AGENT_NAME: 'Sophie Mitchell',
    AGENT_IMAGE: 'https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?w=400',
    BUYER_ID: '5',
    ROLE: 'Education Coordinator',
    COMPANY: 'EduTech Learning'
  },
  // Recruitment Personas
  // ####################################################################################################
  {
    ASSISTANT_ID: 'f3a17e52-4995-4557-8c8d-ff0026f92485', // New ID for James
    AGENT_NAME: 'James Wilson',
    AGENT_IMAGE: 'https://images.unsplash.com/photo-1519085360753-af0119f7cbe7?w=400',
    BUYER_ID: '6',
    ROLE: 'Technical Recruiter',
    COMPANY: 'TalentHub Solutions'
  },
  {
    ASSISTANT_ID: 'f2577cca-29c1-41f3-9a92-b61d58caaef6', // New ID for John
    AGENT_NAME: 'John',
    AGENT_IMAGE: 'https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?w=400&h=400&fit=crop',
    BUYER_ID: '7',
    ROLE: 'Founder & CEO',
    COMPANY: 'Early-stage startup'
  },
  {
    ASSISTANT_ID: '8e284682-e52c-441f-a853-de6d0d3a313d', // New ID for Shimmer
    AGENT_NAME: 'Shimmer',
    AGENT_IMAGE: 'https://images.unsplash.com/photo-1598550874175-4d0ef436c909?w=400&h=400&fit=crop',
    BUYER_ID: '8',
    ROLE: 'CTO',
    COMPANY: 'U.S.-based tech company'
  },
  {
    ASSISTANT_ID: '681f31dc-475c-42f3-ada1-89cf2dcb7dbc', // New ID for Eva
    AGENT_NAME: 'Eva',
    AGENT_IMAGE: 'https://images.unsplash.com/photo-1580489944761-15a19d654956?w=400&h=400&fit=crop',
    BUYER_ID: '9',
    ROLE: 'Head of HR',
    COMPANY: 'Mid-sized company'
  },
  {
    ASSISTANT_ID: 'f3a17e52-4995-4557-8c8d-ff0026f92485', // New ID for James Mitchell
    AGENT_NAME: 'James Mitchell',
    AGENT_IMAGE: 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?w=400&h=400&fit=crop',
    BUYER_ID: '10',
    ROLE: 'Chief Technology Officer',
    COMPANY: 'TechVision Australia'
  },
  {
    ASSISTANT_ID: '863713c0-955c-42f4-9a3c-85677b33d56d', // New ID for Olivia
    AGENT_NAME: 'Olivia Anderson',
    AGENT_IMAGE: 'https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?w=400&h=400&fit=crop',
    BUYER_ID: '11',
    ROLE: 'Head of Digital Innovation',
    COMPANY: 'Sydney Financial Group'
  },
  {
    ASSISTANT_ID: 'a2d7ff53-0d44-41c3-aa9c-aa5bbff81476', // New ID for William
    AGENT_NAME: 'William Cooper',
    AGENT_IMAGE: 'https://images.unsplash.com/photo-1519085360753-af0119f7cbe7?w=400&h=400&fit=crop',
    BUYER_ID: '12',
    ROLE: 'IT Director',
    COMPANY: 'Melbourne Health Tech'
  },
    // ###################################################################################################33#
  {
    ASSISTANT_ID: '6bc58310-12dd-45ff-ab0c-53e800a40e79', // New ID for Sophie Laurent
    AGENT_NAME: 'Sophie Laurent',
    AGENT_IMAGE: 'https://images.unsplash.com/photo-1534528741775-53994a69daeb?w=400&h=400&fit=crop',
    BUYER_ID: '13',
    ROLE: 'Chief Digital Officer',
    COMPANY: 'Paris Innovation Hub'
  },
  {
    ASSISTANT_ID: '943c3afe-4cba-4da3-9bea-7b0f288ac487', // New ID for Klaus
    AGENT_NAME: 'Klaus Weber',
    AGENT_IMAGE: 'https://images.unsplash.com/photo-1562788869-4ed32648eb72?q=80&w=2072&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    BUYER_ID: '14',
    ROLE: 'Head of Enterprise IT',
    COMPANY: 'Berlin Tech Solutions'
  },
  {
    ASSISTANT_ID: '44169406-3aad-4ca6-96e6-387cadb8d309', // New ID for Elena
    AGENT_NAME: 'Elena Rossi',
    AGENT_IMAGE: 'https://plus.unsplash.com/premium_photo-1661576481589-6278a8595fd4?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    BUYER_ID: '15',
    ROLE: 'Innovation Director',
    COMPANY: 'Milano Digital'
  },
  {
    ASSISTANT_ID: '61c484d6-6641-4337-afcd-453ec3ec9ad1', // New ID for Arjun
    AGENT_NAME: 'Arjun Patel',
    AGENT_IMAGE: 'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?w=400&h=400&fit=crop',
    BUYER_ID: '16',
    ROLE: 'Chief Information Officer',
    COMPANY: 'Bangalore Tech Solutions'
  },
  {
    ASSISTANT_ID: 'f8a8ff6a-c079-4a70-bb35-2ff38a4425e2', // New ID for Priya
    AGENT_NAME: 'Priya Sharma',
    AGENT_IMAGE: 'https://images.unsplash.com/photo-1573497019940-1c28c88b4f3e?w=400&h=400&fit=crop',
    BUYER_ID: '17',
    ROLE: 'Head of Digital Strategy',
    COMPANY: 'Mumbai Innovation Labs'
  },
  {
    ASSISTANT_ID: 'bbcee0fa-fc76-4eb3-b538-214df9ecf8be', // New ID for Rajesh
    AGENT_NAME: 'Rajesh Kumar',
    AGENT_IMAGE: 'https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?w=400&h=400&fit=crop',
    BUYER_ID: '18',
    ROLE: 'Technology Director',
    COMPANY: 'Delhi Enterprise Systems'
  }
];

export const getConfigByBuyerId = (buyerId: string) => {
  const config = VAPI_CONFIG.find(config => config.BUYER_ID === buyerId);
  if (!config) {
    throw new Error(`No configuration found for buyer ID: ${buyerId}`);
  }
  return config;
};

// Helper function to find config by buyer name
export const getConfigByBuyerName = (buyerName: string) => {
  const config = VAPI_CONFIG.find(config => config.AGENT_NAME === buyerName);
  if (!config) {
    throw new Error(`No configuration found for buyer name: ${buyerName}`);
  }
  return config;
};

///

  export const VAPI_API_KEY ={
    PRIVATE_API_KEY:"6dfc43b0-7a09-499c-9c21-bc74ebda0eab",
    PUBLIC_API_KEY:"75cb64e3-b6fe-48f9-b1a6-bd80c7b2cc3f"
  }
  
  export const CALL_STATUS = {
    IDLE: 'idle',
    CONNECTING: 'connecting',
    ACTIVE: 'active',
    ENDED: 'ended',
    ERROR: 'error',
  };
  
  export const VAPI_EVENTS = {
    CALL_START: 'call-start',
    CALL_END: 'call-end',
    SPEECH_START: 'speech-start',
    SPEECH_END: 'speech-end',
    ERROR: 'error',
    TRANSCRIPT: 'message',
  };
  
  export const TRANSCRIPT_TYPES = {
    PARTIAL: 'partial',
    FINAL: 'final',
  };
  
  export const DEFAULTS = {
    CALL_TIMEOUT: 30000, 
    RETRY_DELAY: 5000, 
  };
  